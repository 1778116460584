<template>
    <div class="RosetteCustom">
        <div class="schm" v-for="(val, ind) in slted">
            <el-select v-model="val.matt" placeholder="材料">
                <el-option v-for="matte in rstslt.matt" :key="matte.id" :label="$t('cust.' + matte.name)" :value="matte.id">
                </el-option>
            </el-select>
            <el-select v-model="val.wid" collapse-tags style="margin-left: 20px;" placeholder="宽度">
                <el-option v-for="wide in rstslt.matt[val.matt].maxwidth" :key="wide" :label="wide" :value="wide">
                </el-option>
            </el-select>
            <i class="el-icon-close"></i>
        </div>
        <input type="button" >
    </div>
</template>

<script>
import { rstslt, } from './rstslt';

export default {
    name: 'RosetteCustom',
    data() {
        return {
            slted: [
                {
                    matt: 0,
                    wid: 2,
                },
                {
                    matt: 1,
                    wid: 2,
                },
            ],
            rstslt: rstslt
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },
    methods: {
        geneRosette() {
            const g1 = new THREE.RingGeometry(5, 5.2, 64, 1);
            // geometry.attributes.position.array[192] = 0;
            // geometry.attributes.position.array[195] = 0;
            // geometry.attributes.position.array[198] = 0;
            // geometry.attributes.uv = meshes['R1_1'].geometry.attributes.uv;
            var guv = g1.attributes.uv;
            // 0 0.1 1 0.1
            for (let i = 0; i < guv.count; i+=2) {
                guv.array[i] = 0;
                guv.array[i+1] = i/guv.count;
            }
            for (let i = guv.count; i < guv.count*2; i+=2) {
                guv.array[i] = 1;
                guv.array[i+1] = i/guv.count - 1;
            }
            const mesh = new THREE.Mesh(g1, meshes['R1_1'].material);
            mesh.position.set(0, 1.90734, 5.03);
            scene.add(mesh);
            console.log(mesh);
            console.log(g1.attributes.position.array[0]);
        }
    },
    created() {
        console.log(rstslt);
    },
    mounted() {
    },
}
</script>