
import TopMenu from '@/components/TopMenu'
import BottomNav from '@/components/BottomNav'
import ShapeIndi from '@/components/ShapeIndi'
import RosetteCustom from '@/components/RosetteCustom'

// Vue.use({install(Vue)})
function install(Vue) {
    Vue.component(TopMenu.name, TopMenu)
    Vue.component(BottomNav.name, BottomNav)
    Vue.component(ShapeIndi.name, ShapeIndi)
    Vue.component(RosetteCustom.name, RosetteCustom)
}

export default {
    install
}
