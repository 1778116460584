<template>
    <el-menu router :default-active="$route.path" class="gomanav" mode="horizontal"
        text-color="#FFFFFF" active-text-color="#ffffff" background-color="#000" unique-opened menu-trigger="click">
        <el-menu-item index="/">{{ $t('nav.home') }}</el-menu-item>
        <el-menu-item index="/dlx">{{ $t('nav.dlx') }}</el-menu-item>
        <el-menu-item index="/ltd">{{ $t('nav.ltd')}}</el-menu-item>
        <el-submenu index="/guitars" class="guitars" :show-timeout="30" :hide-timeout="380">
            <template slot="title">{{ $t('nav.guitars')}}</template>
            <el-menu-item v-for="shap in shaps" :key="shap.index" :index="shap.link"><span style="vertical-align: auto; font-size: large; font-weight: bold;"> {{ shap.name }} </span> {{ $t('shape')}}</el-menu-item>
            <el-menu-item v-for="fret in fres" :key="fret.index" :index="fret.link">{{ $t('frets.f' + fret.name) }}</el-menu-item>
            <el-menu-item index="/xii">{{ $t('nav.xiistring')}}</el-menu-item>
        </el-submenu>
        <el-menu-item index="/videos">{{ $t('nav.video')}}</el-menu-item>
        <el-menu-item index="/about">{{ $t('nav.about') }}</el-menu-item>
    </el-menu>
</template>
  
<script>
import { shapes } from '@/views/shape/shape';
import { frets } from '@/views/fret/fret';

export default {
    name: 'TopMenu',
    data() {
        return {
            activeIndex: '',
            shaps: {},
            fres: {}
        };
    },
    created() {
        this.shaps = shapes;
        this.fres = frets;
    }
}
</script>

<style scoped>
.gomanav {
    background-color: #111;
}

.el-menu{
    height: 100%;
}
.el-menu-item {
    font-size: large;
    padding: 0 22px;
}

.el-menu.el-menu--horizontal {
    border-bottom: none;
}

.el-menu--horizontal .el-menu .el-menu-item {
    font-size: medium;
}

.el-menu--horizontal>.el-menu-item.is-active, .el-menu--horizontal>.el-submenu.is-active {
    font-weight: bold;
}
</style>

<style>
.el-submenu__title {
    font-size: large;
}
</style>