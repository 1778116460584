<template>
    <div class="GuitarPage">
        <el-row class="pageHead">
            <el-col :xs="24" :sm="24" :md="14">
                <div class="ida">
                    <h1 class="title">{{ specs.model }}</h1>
                    <i v-show="pltg" :class="playin ? 'picon el-icon-video-pause' : 'picon el-icon-video-play'"
                        @click="plau">
                    </i>
                    <span v-show="pltg" class="tuna">{{ $t('ply') }}: {{ specs.ply }}</span>
                    <span v-show="pltg" class="tuna">{{ $t('sng') }}: {{ specs.sti }}</span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10">
                <p class="shapedesc">
                    {{ shapedesc[$i18n.locale] }}
                </p>
            </el-col>
        </el-row>

        <div class="bgp2" :style="{ backgroundImage: 'url(' + specs.pics.bg + ')' }">
            <div class="specification">
                <el-row class="spec_row" v-for="(value, key, index) in specs.spec[$i18n.locale]" :key="index">
                    <el-col class="spec_keys" :xs="24" :sm="7">
                        {{ translation[key] }}
                    </el-col>
                    <el-col :xs="24" :sm="17">
                        {{ value }}
                    </el-col>
                </el-row>
                <br /><br />
                <ShapeIndi :shape="specs.shape" :width="isSmall ? '288px' : '508px'" />
            </div>
        </div>

        <div v-if="specs?.desc[$i18n.locale]" class="desc">
            <p>{{ specs?.desc[$i18n.locale] }}</p>
        </div>

        <h2 class="detail">{{ $t('detail') }}</h2>

        <div class="imgsecm" v-if="isMobile">
            <el-image class="im1m" v-for="pic in specs.pics.special" :key="pic" :src="pic" fit="contain">
                <div slot="placeholder" class="imgl-slot">
                    <img src="/loading.jpg" class="imgl">
                </div>
                <div slot="error" class="error-slot">
                </div>
            </el-image>
        </div>

        <div class="imgsec" v-else>
            <div class="image-scroller">
                <el-image v-for="image in specs.pics.special" class="elim" :key="image.index" :src="image"
                    :preview-src-list="specs.pics.special" @error="moveErrorImg">
                    <div slot="placeholder" class="imgl-slot">
                        <img src="/loading.jpg" class="imgl2">
                    </div>
                    <div slot="error" class="error-slot">
                    </div>
                </el-image>
            </div>
            <div class="prevdiv">
                <el-carousel :autoplay="false" arrow="always" trigger="click" :height="'888px'">
                    <el-carousel-item v-for="image in specs.pics.special" :key="image.index">
                        <img class="carousel" :src="image" alt="value.alt">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div v-if="specs.video[$i18n.locale]" class="vid">
            <iframe v-if="lang" :src="specs.video.zh" loading="lazy" :width="isSmall ? 300 : 560" :height="isSmall ? 168 : 315"
                scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"
                sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"> </iframe>
            <iframe v-else :src="specs.video.en" :width="isSmall ? 300 : 560" :height="isSmall ? 168 : 315"
                title="YouTube video player" frameborder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import { zhL, enL, frL } from "@/i18n/trans";
import { guitarSpecs } from "./allmodels";
import { shapes } from "../shape/shape";

export default {
    name: 'Guitar',
    data() {
        return {
            shapedesc: "",
            specs: {},
            translation: {},
            audi: new Audio(),
            playImg: require("@/assets/play.png"),
            pauseImg: require("@/assets/pause.png"),
            playin: false,
            pltg: false
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (this.audi) {
            this.pltg = false;
            this.playin = false;
            this.audi.pause();
            this.audi = null;
        }
        document.title = "GomansGuitar";
        next()
        let param = this.$route.params.param;
        param = param.replaceAll('-', '_');
        if (param.startsWith('0')) {
            param = 'o' + param;
        }
        if (param in guitarSpecs) {
            this.specs = guitarSpecs[param];
            let shap = this.specs.shape.toLowerCase().replaceAll('0', 'o');
            this.shapedesc = shapes[shap].desc;
            if (this.$i18n.locale == 'en') {
                this.translation = enL.guitarProps;
            } else if (this.$i18n.locale == 'zh') {
                this.translation = zhL.guitarProps;
            } else {
                this.translation = frL.guitarProps;
            }
            if (this.specs.aud) {
                if (this.audi == null) {
                    this.audi = new Audio();
                }
                this.audi.src = this.specs.aud;
                window.plau = this.plau;
                this.pltg = true;
            }
            document.title = this.specs.model + " - GomansGuitar";
        }
        else {
            this.$router.push('/404');
        }
    },
    methods: {
        moveErrorImg(event) {
            event.currentTarget.style.display = "none";
            // event.currentTarget.style.width = "auto";
            return true;
        },
        async plau(event) {
            if (this.playin) {
                this.audi.pause();
                this.playin = false;
            } else {
                await this.audi.play();
                this.playin = true;
            }
        }
    },
    watch: {
        '$i18n.locale'(newValue) {
            if (this.$i18n.locale == 'zh') {
                this.translation = zhL.guitarProps;
            } else if (this.$i18n.locale == 'fr') {
                this.translation = frL.guitarProps;
            } else {
                this.translation = enL.guitarProps;
            }
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale == 'zh';
        },
        isSmall() {
            // Add a logic to determine if the device is mobile
            // You can use window.innerWidth or any other method for device detection
            return window.innerWidth <= 580; // Change the breakpoint as needed
        },
        isMobile() {
            return window.innerWidth <= 1000;
        },
    },
    created() {
        // 获取请求的吉他型号
        let param = this.$route.params.param;
        param = param.replaceAll('-', '_');
        if (param.startsWith('0')) {
            param = 'o' + param;
        }
        // 从guitarSpecs取出对应型号数据放入data
        if (param in guitarSpecs) {
            this.specs = guitarSpecs[param];
            let shap = this.specs.shape.toLowerCase().replaceAll('0', 'o');
            this.shapedesc = shapes[shap].desc;
            if (this.$i18n.locale == 'en') {
                this.translation = enL.guitarProps;
            } else if (this.$i18n.locale == 'zh') {
                this.translation = zhL.guitarProps;
            } else {
                this.translation = frL.guitarProps;
            }
            if (this.specs.aud) {
                this.audi.src = this.specs.aud;
                window.plau = this.plau;
            }
        }
        else {
            this.$router.push('/404');
        }
    },
    mounted() {
        document.title = this.specs.model + " - GomansGuitar";
        if (this.$i18n.locale == 'zh') {
            document.getElementsByClassName('shapedesc')[0].style['text-indent'] = "2em";
            // if (this.specs.desc['zh']) {
            //     document.getElementsByClassName('desc')[0].style['text-indent'] = "2em";
            // }
        }
        if (this.specs.aud) {
            this.pltg = true;
        }
    },
    beforeDestroy() {
        if (this.audi) {
            this.pltg = false;
            this.audi.pause();
            this.audi = null;
        }
        document.title = "GomansGuitar";
    }
}

</script>

<style scoped>
.GuitarPage {
    color: #FFF;
}

.pageHead {
    background: linear-gradient(275deg, #141817, #202324);
}

.ida {
    padding: 0 8px 0 68px;
    margin: 38px 0;
    text-align: left;
}

.title {
    margin: 0;
    line-height: 1.1;
    font-family: AVGARDN, "Helvetica Neue";
    font-size: 10ch;
    font-weight: normal;
    text-shadow: 1px 1px 6px #111;
}

.picon {
    margin: 8px 8px 0 6px;
    scale: 1.8;
    cursor: pointer;
    text-align: left;
}

.tuna {
    display: inline-block;
    margin: 8px 8px;
    text-align: center;
    font-size: small;
}

.shapedesc {
    padding: 3px 68px;
    line-height: 1.58;
    font-family: bahnschrift, "Helvetica Neue";
    text-align: justify;
    font-size: 2ch;
}

.specification {
    margin: 20px 50px;
    width: 80%;
    max-width: 688px;
    line-height: 2ch;
    text-align: left;
    font-weight: bold;
    font-size: 2.2ch;
    text-shadow: 1px 1px 5px #111;
    color: #fff;
}

.spec_row {
    margin: 1.2ch 0;
}

.spec_keys {
    font-family: bahnschrift, "Helvetica Neue";
    font-weight: normal;
    font-size: 1.7ch;
    color: #f2f2f2;
}

.bgp2 {
    height: 80%;
    min-height: 588px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 15px;
    color: #000;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-attachment: fixed;
}

.desc {
    padding: 38px 88px;
    line-height: 1.88;
    text-align: center;
    font-family: bahnschrift, "Helvetica Neue";
    font-size: larger;
    white-space: pre-line;
}

.vid {
    height: auto;
    margin-top: 18px;
    padding: 38px 0;
    background-color: #111;
}

.detail {
    font-size: 5ch;
    width: 100%;
    margin: 58px 0;
}

.block {
    display: inline-block;
    width: 280px;
    height: 280px;
}

.el-carousel__item:nth-child(2n) {
    background-color: #000000;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #000000;
}

img.carousel {
    height: 100%;
}

.scroller {
    display: flex;
    overflow-x: auto;
}

.image-scroller {
    padding: 18px 0;
    justify-content: center;
    overflow-x: scroll;

    background-color: #161616;
    white-space: nowrap;
}

.image-scroller ::-webkit-scrollbar {
    display: none
}

.elim {
    display: inline-block;
    height: 188px;
    margin: 8px;
    cursor: pointer;
}

.prevdiv {
    height: 880px;
}

.error-slot {
    height: 0;
    width: 0;
}

.imgl-slot {
    width: 100%;
    height: auto;
}

.imgl {
    width: 100%;
}

.imgl2 {
    height: 188px;
}

#text {
    font-size: medium;
}

@media screen and (max-width: 768px) {
    .ida {
        padding-left: 8px;
        text-align: center;
    }

    .picon {
        margin: 18px 0 18px 0;
        scale: 2.8;
        cursor: pointer;
        text-align: left;
    }

    .tuna {
        display: block;
    }

    .title {
        font-weight: bold;
        font-size: 6ch;
        letter-spacing: 1px;
    }

    .specification {
        margin: 20px 30px;
    }

    .shapedesc {
        padding: 3px 28px;
    }

    .spec_keys {
        font-size: large;
    }
}
</style>
