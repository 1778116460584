<template>
    <div class="home">
        <el-carousel :interval="8888" :height="isMobile ? '168px' : '398px'">
            <el-carousel-item v-for="(image, index) in carouselPics" :key="index" :name="image.alt"
                :label="image.label">
                <img class="carousel" :src="image[$i18n.locale]" :alt="image.alt" @click="$router.push(image.link)"
                    @load="updateCarouselHeight(index)">
            </el-carousel-item>
        </el-carousel>

        <el-row class="carou">
            <el-col v-for="carou in carous" :key="carou.index" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <div class="cacol">
                    <h2 class="tit">
                        {{ $t(carou.tit) }}
                    </h2>
                    <div class="desc">
                        {{ $t(carou.desc) }}
                    </div>
                    <img class="caimg" :src="carou.link" :alt="carou.alt" />
                </div>
            </el-col>
        </el-row>


        <!-- <div class="hpic homedlx" style="background-image: url('/home/dlx.jpg');" @click="$router.push('/dlx')">
                <h1>{{ $t('nav.dlx') }}</h1>
            </div> -->

        <a class="hpic" style="background-image: url('/home/cust.jpg');" href="/cust">
            <h1>{{ $t('clk') }} <br /> {{ $t('cust.tit') }}</h1>
        </a>

        <div class="hpic homevr" @click="$router.push('/vr')" style="background-image: url('/home/vr.jpg');">
            <h1>{{ $t('vr') }}</h1>
        </div>
        <h1 class="gal">{{ $t('gal') }}</h1>
        <img class="homeimg" src="/home/02.jpg" alt="Gomans French Touch">
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            qr: false,
            carouselPics: [
                { zh: "2024cn.jpg", en: "2024en.jpg", fr: "2024en.jpg", alt: "SNIEC2024", label: "", link: "/cust" },
                { zh: "kpcn.jpg", en: "kp.jpg", fr: "kp.jpg", alt: "KP", label: "", link: "/guitar/G-S18AC-KP" },
                { zh: "couplezh.jpg", en: "couple.jpg", fr: "couple.jpg", alt: "Couple", label: "", link: "/guitar/G-S21DCTB" },
                { zh: "01.jpg", en: "01.jpg", fr: "01.jpg", alt: "000-S18AC DLX", label: "", link: "/guitar/000-S18AC_DLX" },
            ],
            carouselHeight: '233',
            carous: [
                { alt: "GomansGuitar", label: "", link: "/c1.jpg", tit: "home.title1", desc: "home.desc1" },
                { alt: "GomansGuitar", label: "", link: "/c4.jpg", tit: "home.title2", desc: "home.desc2" },
                { alt: "GomansGuitar", label: "", link: "/c3.jpg", tit: "home.title3", desc: "home.desc3" },
            ]
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },
    methods: {
        updateCarouselHeight(index) {
            const imgElement = this.$el.querySelectorAll(".el-carousel__item img")[index];
            if (imgElement) {
                const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
                const carouselWidth = this.$el.getBoundingClientRect().width;
                this.carouselHeight = String(Math.ceil(carouselWidth / aspectRatio));
            }
        },
        moveErrorImg(event) {
            event.currentTarget.style.display = "none";
            return true;
        }
    },
    created() {
        // if (this.$route.query.qr) {
        //     this.qr = this.$route.query.qr;
        //     // todo 发给后端
        //     this.$router.push({ query: {} }); // 删除页面参数
        // }
    },
    mounted() {
        document.title = "GomansGuitar";
        this.updateCarouselHeight(0);
    },
}
</script>

<style scoped>
div {
    color: #fff;
}

img.carousel {
    cursor: pointer;
    height: 100%;
}

img.homeimg {
    width: 100%;
    height: auto;
}

a {
    margin: 6px 0;
    text-decoration: none;
    color: #ffffff;
}

/* 状态二: 已经访问过的链接 */
a:visited {
    text-decoration: none;
    color: #ffffff;
}

.homevr {
    aspect-ratio: 3.891;
    min-height: 188px;
}

.hpic {
    width: 100%;
    height: auto;
    text-shadow: 5px 5px 12px #111;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: local;
    background-size: cover;
    cursor: pointer;
}

.hpic h1 {
    margin: 0;
    padding: 25px 0;
}

.gal {
    margin: 0;
    padding: 38px 0;
    background-color: #0b0b0e;
    text-shadow: 5px 5px 12px #000;
}

.el-carousel {
    height: var(--carousel-height);
    overflow: hidden;
}

.el-carousel__container {
    position: absolute;
    height: auto;
}

.el-carousel__item h3 {
    color: #000000;
}

.el-carousel__item:nth-child(2n) {
    background-color: #000000;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #000000;
}

.carou {
    padding: 28px 0 68px 0;
    background-color: #222;
}

.cacol {
    text-shadow: 1px 1px 6px #000;
}

.desc {
    padding: 0 18px;
    line-height: 1.28;
    height: 43px;
}

.caimg {
    margin: 18px 0;
    box-shadow: 2px 2px 8px 3px #0a0a0a;
}

@media screen and (max-width: 900px) {
    .hpic h1 {
        font-size: 2.5em;
    }

}
</style>
