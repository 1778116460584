import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {
  Image, Icon, Carousel, CarouselItem,
  Row, Col, Tabs, TabPane, Progress,  Checkbox, CheckboxGroup, CheckboxButton, Radio,
  RadioGroup, RadioButton, Option, OptionGroup, Input, Select, Dialog, Message, MessageBox, 
  // Popover,
  Container, Header, Main, Footer, Dropdown, DropdownMenu, DropdownItem, Menu, Submenu, MenuItem, MenuItemGroup,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueI18n from 'vue-i18n'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import frLocale from 'element-ui/lib/locale/lang/fr'
import { zhL, enL, frL } from './i18n/trans'

Vue.use(VueI18n)

const messages = {
  zh: Object.assign(zhL, zhLocale),
  en: Object.assign(enL, enLocale),
  fr: Object.assign(frL, frLocale),
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en',
  messages, // set locale messages
  fallbackLocale: 'en', // Fallback locale if the current locale's message is not found
})

Vue.use({
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Input);
Vue.use(Select);
Vue.use(Dialog);
// Vue.use(Popover);
Vue.prototype.$message = Message;
// MessageBox
Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

// 下面的css会覆盖上面的
import './assets/index.css';

import './assets/font/index.css' //全局使用

import plugins from '@/plugins';
Vue.use(plugins);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
