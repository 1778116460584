export const rstPreset = {

}

export const rstslt = {
    matt: [
        {
            id: 0,
            name: 'emt',
            mat: '',
            pic: "nil",
            step: 0.5,
            maxwidth: 5.0
        },
        {
            id: 1,
            name: 'aba',
            mat: 'prfAba',
            pic: "aba",
            step: 0.5,
            maxwidth: 2.0
        },
        {
            id: 2,
            name: 'wba',
            mat: 'prfWba',
            pic: "wba",
            step: 0.5,
            maxwidth: 2.0
        },
        {
            id: 3,
            name: 'map',
            mat: 'bndMap',
            pic: "wood/map",
            step: 1,
            maxwidth: 5.0
        },
        {
            id: 4,
            name: 'ros',
            mat: 'bndRos',
            pic: "wood/ros",
            maxwidth: 5.0
        },
        {
            id: 5,
            name: 'red',
            mat: 'bndRed',
            pic: "wood/red",
            maxwidth: 5.0
        },
        {
            id: 6,
            name: 'coc',
            mat: 'bndCoco',
            pic: "bnd/coc",
            maxwidth: 5.0
        },
    ]
}

export const rstwid = {

}