export const zhL = {
    message: "你好",
    series: "系列",
    shape: "形",
    fret: "品",
    Fan: "扇",
    detail: "细节展示",
    xiistring: "十二弦",
    rare: "珍稀木材，尊贵藏品",
    vid: "视见 Gomans",
    ply: "吉他用家",
    sng: "歌曲",
    gal: "图册",
    clk: "点击进入",
    vr: "Gomans VR 展厅",
    anoun: "声明",
    currency: "¥",
    nav: {
        home: "主页",
        guitars: "所有乐器",
        dlx: "DLX 系列",
        ltd: "Gomans 特典",
        xiistring: "十二弦系列",
        ff: "十二品系列",
        artist: "演奏家",
        video: "视频",
        about: "关于我们",
    },
    footer: {
        video: "官方号",
        wx: "微信公众号",
        tb: "淘宝店",
        bili: "哔哩哔哩",
        contact: "联系我们",
        copyright: "广州高民木制品有限公司版权所有"
    },
    home: {
        title1: "技精则音至",
        desc1: "超十年经验积累，我们做出的吉他音色能够直触人心。",
        title2: "特挑木材",
        desc2: "每一片木材都要经过我们的严格筛选，才能被做进我们的Gomans吉他。",
        title3: "自研音梁",
        desc3: "我们立足传统，不断创新音梁的设计，至今从未止步。",
    },
    dlx: {
        fea: "特点",
        brac: "镂空音梁",
        nec: "五拼一体琴柄",
        ash: "臂枕音孔",
        bdc: "斜角半切口",
        ssh: "侧面音孔",
    },
    ltd: {
        tit: "Gomans 特典",
        ltd: "限量版",
        spe: "特别版",
        sc: "Gomans与曹树堃合作系列"
    },
    xii: {
        desc: "十二弦吉他的音色饱满，高亮光泽，效果好过两把六弦吉他一起弹奏。音乐宽广，弹奏起来效果更好，并且更为深邃。所能诠释出来的便是六弦吉他无法弹奏的宽广音域。"
    },
    shapes: {
        bdl: "琴身长",
        shd: "肩宽",
        wai: "腰宽",
        tum: "肚宽",
        upt: "上厚",
        dot: "下厚",
        stl: "有效弦长",
        tot: "总长"
    },
    frets: {
        f12: "12 品",
        f16: "16 品",
        fFan: "扇品",
    },
    guitarProps: {
        series: "系列",
        name: "名字",
        model: "型号",
        shape: "琴型",
        Top: "面板",
        BackSides: "背侧板",
        head: "琴头",
        Neck: "琴柄",
        Fingerboard: "指板",
        Nut: "上弦枕",
        Saddle: "下弦枕",
        TuningMachine: "弦准",
        String: "弦线",
        FinishTop: "面处理",
        FinishNeck: "柄处理",
        FinishBody: "鼓处理",
        // cust
        body: "琴桶",
        baksid: "背侧板",
        fb: "指板材质",
        fbinlay: "指板镶嵌",
        fbdot: "指板音点",
        fbnd: "指板包边",
        fbprf: "指板小线",
        brg: "琴桥",
        rst: "口轮花",
        neck: "琴柄",
        heel: "尾片形状",
        Nek: "琴柄",
        tail: "尾片",
        fanfret: "扇品",
        headStock: "琴头",
        hpl: "琴头贴面",
        hprf: "琴头小线",
        hlogo: "琴头标志",
        prf: "小线",
        bnd: "包边",
        spf: "侧小线",
        bpf: "背小线",
        cut: "缺角",
        cpl: "缺角木材",
        hrs: "手枕",
        wc: "腰枕",
        btm: "底部镶嵌",
        newi: "山口宽度",
        mnh: "监听孔",
        bbd: "背中线",
        tm: "弦准"
    },
    customWelcome: {
        tit: "欢迎来到Gomans吉他线上定制工场",
        pls: "请选择您要定制的桶型",
        bdl: "长",
        tum: "宽",
        upt: "上厚",
        dot: "下厚",
    },
    cust: {
        tit: "Gomans吉他定制工场",
        alm: "即将开启",
        prd: "点击价格以显示明细→",
        crs: "Gomans口轮花定制",
        dis: "预览仅供参考，最终成品外观会因为材料纹理不同而不同。",
        disc: "由于Gomans吉他为手工制作，制琴师在制琴过程中可能会由于声学需要，对手枕、缺角等非定量部位的部分尺寸进行微调，且不另行通知。",
        beta: "当前Gomans吉他在线定制处于公测阶段，可能有部分显示异常，您仍可正常下单，下单后我们会与您二次核对订单。",
        restore: '复原所有定制选项',
        share: '分享您的吉他设计',
        exit: "确认退出Gomans吉他定制吗？我们将为您当前的吉他设计保存一段时间。\r\n您也可以点击右上角菜单，截图保存您设计好的吉他链接二维码",
        done: "完成",

        total: "总计",
        bas: "基础价格",
        tbd: "待定",

        shape: "桶型",
        G: "G",
        Gshape: 'G 型',
        // Top
        sit: "西特卡云杉",
        adi: "阿迪朗达云杉",
        cda: "红松单板",
        scd: "水浸红松单板",
        mon: "欧洲月亮云杉",
        // BackSides
        mah: "桃花芯木",
        fah: "强影桃花芯木",
        pad: "红花梨",
        bpk: "巴西花梨",
        ros: "玫瑰木",
        irs: "印度玫瑰木",
        sat: "桑托斯玫瑰木",
        koa: "相思木",
        map: "枫木",
        fmp: "强影枫木",
        zir: "破布木",
        red: "红木",
        lit: "荔枝木",
        zhn: "金丝楠木",
        coc: "可可菠萝玫瑰木",
        ebn: "乌木",
        sap: "沙比利",
        // Rosette
        wab: "地图纹 + 彩贝",
        wli: "地图纹 + 玫白玫",
        spa: "沙比利 + 彩贝",
        mpr: "枫木 + 玫瑰木",
        col: "可可菠萝 + 玫白玫",
        rol: "玫瑰木 + 玫白玫",
        coa: "可可菠萝 + 彩贝",
        rlw: "玫瑰木 + 玫白玫",
        mli: "枫木 + 小线",
        mab: "枫木 + 彩贝",
        j18: "J18口轮花",
        emt: "留空",
        // prf
        aba: "彩贝",
        rwr: "玫白玫",
        // bnd
        mrw: "枫木 + 玫白玫",
        // Bridge
        bsd: "Standard Bridge",
        bfs: "扇品琴桥",
        bmp: "枫木琴桥",
        brd: "红木琴桥",
        // Finish
        gls: "亮光",
        stn: "哑光",
        // 切角
        cnl: "无缺角",
        c: "尖缺角",
        dc: "圆缺角",
        hc: "半缺角",
        hcb: "半缺角有包边",
        ac: "圆润半缺角",
        wc: "腰枕",
        // 手枕
        hrn: "无手枕",
        hrg: "G型手枕",
        hrc: "C型手枕",
        hrh: "音孔手枕",
        // NeckShape
        nsd: "经典柄形",
        nft: "平柄",
        // Neck
        nmr: "桃花芯拼玫瑰木",
        nmm: "枫木拼桃花芯",
        npr: "枫木拼玫瑰木",
        nrr: "枫木拼红木",
        ntr: "春茶拼玫瑰木",
        // 山口
        nw2: "42毫米",
        nw3: "43毫米",
        nw4: "44毫米",
        // 音点
        rwa: "白色音点",
        rba: "黑色音点",
        rab: "彩贝音点",
        rwb: "白贝音点",
        agw: "直角白色",
        // fbin
        nil: "无",
        mpa: "枫木+彩贝侧贴片",
        mpl: "枫木+小线侧贴片",
        wdo: "白贝圆点",
        dma: "菱形彩贝",
        lpa: "彩贝圈圈",
        sgn: "Gomans签名",
        gpe: "葡萄叶",
        // fanfret
        nff: "平品",
        f25: "625-648mm",
        f35: "635-670mm",
        // HeadStock
        gsh: "标准琴头",
        ghh: "镂空琴头",
        hmt: "方形琴头",
        // tuning Machine
        ggb: "金色全封闭带金色钮",
        gtm: "Gomans弦准",
        g51: "Gotoh 510",
        gop: "Grover开放式",
        // 琴头小线
        wba: "白贝",
        // logo
        nlg: "无标志",
        gla: "彩贝G标",
        glw: "白贝G标",
        glm: "枫木G标",

        mnh: "监听孔",
        // 镶嵌冲突提示
        din: "您选择了指板镶嵌，已为您取消直角音点。",
        ics: "来图定制",
        rstcst: "Gomans口轮花定制"
    },
    custL: {
        l0: "欢迎来到Gomans吉他定制工场",
        l10: "正在运输木材..",
        l20: "正在精挑木材..",
        l30: "正在镶嵌口轮花..",
        l40: "正在精修音梁..",
        l50: "正在合并琴桶..",
        l60: "正在接柄..",
        l70: "正在打磨喷漆..",
        l80: "正在装配..",
        l90: "正在布置场景..",
        l100: "开始定制",
    },
    smt: {
        tit: "提交",
        pli: "请输入",
        slc: "或选择您的国家/地区",
        region: "国家/地区",
        name: "姓",
        lsn: "名",
        nam: "姓名",
        email: "电子邮箱",
        contact: "联系方式",
        rem: "备注",
        rema: "其他联系方式，或特殊要求",
        // rtn: "提交成功！感谢您的订单！Gomans吉他首席制琴师将在一个工作日内联系您！",
        wrg: "请选择正确的国家/地区",
        wnm: "抱歉，姓名需要两个字或以上",
        wem: "电子邮箱格式不正确",
        wct: "抱歉，联系方式需要三个字或以上",
        success: "提交成功！感谢您的订单！Gomans吉他首席制琴师将在三个工作日内联系您！",
    },
    cpt: {
        init: "请点击按键开始人机验证",
        ing: "正在进行人机验证...",
        fai: "人机验证失败",
        ret: "点击重试",
    },
    about: {
        int: "品牌介绍",
        intro: "Gomans Guitar是由法裔华人制琴师Tak Chan先生创立的。",
        wd0: "缘何 爱琴",
        wd1: "其声，松心",
        wd2: "其音，悦人",
        wd3: "以乐交友，如若高山流水",
        wd4: "",
        wd5: "",
        co: "广州高民木制品有限公司",
        addr: "中国广东省广州市从化区太平镇邓村四队16号",
        post: "510990",
        name: "徐小姐",
        tel: "电话：13926110081  微信同步",
        founder: "Gomans Guitar 创始人",
    },
    err: {
        error: "发生错误：",
        ser: "很抱歉，服务器发生错误，请点击右上角菜单截图配置码发到邮箱：info@gomansguitar.com",
        pend: "这款吉他仍在研发中...",
        ret: "返回首页",
        sto: "当前页面已超时，请刷新重试",
        mus: "您已提交过相同请求，我们会尽快联系您",
        mul: "您已提交两把吉他，请等待制琴师与您取得联系后再继续提交，谢谢合作。",
        inv: "非常抱歉，您填写的信息未通过验证"
    }
}


export const enL = {
    message: "Hello",
    series: "SERIES",
    shape: "SHAPE",
    fret: "Fret",
    detail: "DETAILS",
    xiistring: "12 STRINGS",
    rare: "Made from Rare Wood",
    vid: "See Gomans",
    ply: "Player",
    sng: "Song",
    gal: "Gallery",
    clk: "Click to Enter",
    vr: "Gomans VR Exhibition Hall",
    anoun: "Anouncement",
    cook: "www.gomansguitar.com uses cookies to deliver and enhance the quality of our services and to analyze traffic.",
    currency: "¥",
    nav: {
        home: "HOME",
        guitars: "ALL GUITARS",
        dlx: "DLX SERIES",
        ltd: "GOMANS SPECIAL",
        xiistring: "12 STRINGS SERIES",
        f12: "12 Fret",
        f16: "16 Fret",
        ff: "Fan-Fret",
        artist: "PLAYERS",
        video: "VIDEOS",
        about: "ABOUT US",
    },
    footer: {
        video: "FOLLOW US",
        wx: "WeChat Offcial Account",
        tb: "TaoBao Store",
        bili: "Bilibili",
        contact: "CONTACT US",
        copyright: "Copyright 2013 - 2023 © GomansGuitar.com All rights reserved."
    },
    home: {
        title1: "Accuracy for best sound ",
        desc1: "Precision measurements with very close tolerances.",
        title2: "Selected Wood",
        desc2: "We only use wooden pieces that has good grain, density and stiffness. ",
        title3: "Pro Designed Bracing",
        desc3: "Based on tradition, we kept innovating the formation of our bracing. ",
    },
    dlx: {
        fea: "FEATURES",
        brac: "Drilled Holes Bracing",
        nec: "Five Pieces Neck",
        ash: "Armrest Sound Holes",
        bdc: "Bevel Demi Cutaway",
        ssh: "Side Sound Hole",
    },
    ltd: {
        tit: "Gomans Special",
        ltd: "Limited Collection",
        spe: "Special Collection",
        sc: "Gomans & Scott Cao"
    },
    xii: {
        desc: "A 12 string guitar has a full and shiny sound, It can give you a sound so wide that a six string guitar cannot have."
    },
    shapes: {
        bdl: "Body Length",
        shd: "Upper Bout Width",
        wai: "Waist Width",
        tum: "Lower Bout Width",
        upt: "Body Depth Neck",
        dot: "Body Depth Tail",
        stl: "Scale Length",
        tot: "Total Length"
    },
    frets: {
        f12: "12 Fret",
        f16: "16 Fret",
        fFan: "Fan Fret",
    },
    guitarProps: {
        series: "Series",
        name: "Name",
        model: "Model",
        shape: "Shape",
        pics: "",
        Top: "Top",
        BackSides: "BackSides",
        head: "HeadStock",
        Neck: "Neck",
        tail: "Tail",
        Fingerboard: "Fingerboard",
        Nut: "Nut",
        Saddle: "Saddle",
        TuningMachine: "Tuning Machine",
        String: "String",
        FinishTop: "Finish Top",
        FinishNeck: "Finish Neck",
        FinishBody: "Finish Body",
        // cust
        body: "Body",
        baksid: "BackSides",
        brg: "Bridge",
        rst: "Rosette",
        prf: "Perfling",
        bnd: "Binding",
        spf: "Side Perflings",
        bbd: "Back strip",
        bpf: "Back Prefling",
        cut: "Cutaways",
        cpl: "Cutaway wood",
        hrs: "ArmRest",
        mnh: "Side Port",
        wc: "Waist rest",
        btm: "Bottom Style",
        // Neck
        heel: "Heel",
        neck: "Neck",
        Nek: "Neck",
        newi: "Neck Width",
        fb: "Fingerboard",
        fbinlay: "Fb. Inlay",
        fbdot: "Fret dot",
        fbnd: "Fb. Binding",
        fbprf: "Fb. perfling",
        fanfret: "FanFret",
        // Head
        hlogo: "Head Logo",
        headStock: "Head",
        hpl: "Head Plate",
        hprf: "Head perfling",
        tm: "Tuners"
    },
    customWelcome: {
        tit: "Welcome to Gomans Custom build",
        pls: "Please choose the shape of your design",
        bdl: "Tall",
        tum: "Width",
        upt: "Upper Depth",
        dot: "Tail Depth",
    },
    cust: {
        tit: "Gomans Custom Build",
        alm: "Loading",
        prd: "Press the price button to see the details →",
        dis: "Online preview for reference only, the final guitar look will vary depending on the certain material used.",
        disc: "由于Gomans吉他为手工制作，制琴师在制琴过程中可能会由于声学需要，对手枕、缺角等非定量部位的部分尺寸进行微调，且不另行通知。",
        beta: "Gomans Guitar Online Custom is currently in beta test, something may look wrong, you can still place your orders, we will review the details of your order with you later.",
        restore: "Restore Selections to default",
        crs: "Custom Rosette",
        share: 'Share your design',
        resto: "Restore Selections",
        exit: "Are you sure you want to quit Gomans Guitar Custom？We will save your design for a short time.",
        done: "Done",

        total: "Total",
        bas: "Base price",
        tbd: "To be done",

        shp: "Shape",
        G: "G",
        Gshape: 'G shape',
        // Top
        sit: "Solid Sitka Spruce",
        adi: "Solid Adirondack Spruce",
        cda: "Solid Red Cedar",
        scd: "Solid Sinker Red Cedar",
        mon: "Solid Moon Spruce",
        // BackSides
        mah: "Solid Mahogany",
        pad: "Solid Padouk",
        bpk: "Solid Brazillian Padouk",
        ros: "Solid Rosewood",
        irs: "Solid Indian Rosewood",
        sat: "Solid Santos Rosewood",
        koa: "Solid Acacia Koa",
        map: "Solid Maple",
        fmp: "Solid Flammed Maple",
        fah: "Solid Flammed Mahogany",
        zir: "Solid Ziricote",
        red: "Solid Red Wood",
        coc: "Solid Cocobolo",
        ebn: "Solid Ebony",
        sap: "Solid Sapele",
        zhn: "Solid Zhennan",
        lit: "Solid Lychee Wood",
        // Rosette
        wab: "Wood + Abalone",
        wli: "Wood + Lines",
        spa: "Sapele + Abalone",
        mpr: "Maple + Rosewood",
        rol: "Rosewood + Lines",
        col: "Cocobolo + Lines",
        coa: "Cocobolo + Abalone",
        rlw: "Rosewood + Lines",
        mli: "Maple + Lines",
        mab: "Maple + Abalone",
        j18: "J18 R",
        emt: "Gap",
        // prf
        aba: "Abalone",
        wba: "Pearl",
        rwr: "Lines",
        // bnd
        mrw: "Maple + Lines",
        mp: "Flammed Maple",
        // Bridge
        bsd: "Standard Bridge",
        bfs: "Fan-Fret Bridge",
        bmp: "Maple Bridge",
        brd: "Padouk Bridge",
        // Finish
        gls: "Gloss",
        stn: "Satin",
        // 切角
        cnl: "No Cutaway",
        c: "Florentin Cutaway",
        dc: "DC Cutaway",
        hc: "HC Bevel Cutaway",
        // hcb: "半缺角有包边",
        ac: "AC Bevel Cutaway",
        wc: "Waist rest",
        // 手枕
        hrn: "No Armrest",
        hrg: "Armrest G",
        hrc: "Armrest C",
        hrh: "Armrest w/ Sound Holes",
        // NeckShape
        nsd: "Standard Heel",
        nft: "Flat Heel",
        // Neck
        nmr: "Mahogany/Rosewood",
        nmm: "Maple/Mahogany",
        npr: "Maple/Rosewood",
        nrr: "Maple/Padouk",
        ntr: "春茶拼玫瑰木",
        // 山口
        nw2: "42mm",
        nw3: "43mm",
        nw4: "44mm",
        // 音点
        rwa: "white dot",
        rba: "black dot",
        rab: "abalone dot",
        rwb: "pearl dot",
        agw: "white angled",
        // fbin
        nil: "None",
        mpa: "Maple + Abalone",
        mpl: "Maple + lines",
        wdo: "Pearl dot",
        dma: "Diamond Abalone",
        lpa: "Abalone circles",
        sgn: "Gomans Signature",
        gpe: "Grape Leaf",
        // fanfret
        nff: "Normal Fret",
        f25: "625-648mm",
        f35: "635-670mm",
        // HeadStock
        gsh: "Standard HeadStock",
        ghh: "Hollow HeadStock",
        // tuning Machine
        gtm: "Gomans Gold",
        g51: "Gotoh 510",
        gop: "Grover open",
        // 琴头小线
        // logo
        nlg: "No Logo inlay",
        gla: "Abalone G Logo",
        glw: "Pearl G Logo",
        glm: "Maple G Logo",

        mnh: "Side Port",
        syb: "test",
        
        din: "You have added fingerboard inlay, angled fret dot is automatically cancelled.",
        ics: "Personal Customization",
        rstcst: "Gomans\nRosette Custom"
    },
    custL: {
        l0: "Welcome to Gomans Guitar Custom Builder",
        l10: "Loading logs..",
        l20: "Selecting wood..",
        l30: "Mounting Rosette..",
        l40: "Tuning Bracing..",
        l50: "Assembling Body..",
        l60: "Necking..",
        l70: "Finishing..",
        l80: "Attaching Bridge..",
        l90: "Setting up scene..",
        l100: "Build your guitar",
    },
    smt: {
        tit: "Submit",
        srq: "Submit Custom Request",
        region: "Country/Region",
        pli: "Please enter ",
        slc: " or select your country/region",
        nam: "Full Name",
        email: "Email Address",
        contact: "other Contact method",
        rem: "Remarks",
        rema: "other contact methods or special requirements",
        name: "Last Name",
        lsn: "First Name",
        contact: "Contact",
        success: "Thanks for submiting！Gomans Guitar chief luthier will contact you in 3 work day！",
        wrg: "Please choose a correct country/region",
        wnm: "Sorry, your name should be at least 2 characters",
        wem: "Invalid Email Address",
        wct: "Sorry, your contact should be at least 3 characters",
    },
    about: {
        int: "品牌介绍",
        intro: "Gomans Guitar was founded by Mr. Tak Chan, a French guitarist. ",
        wd0: "SOME WORDS FROM TAK",
        wd1: "Gomans Guitar was created because I have been loving to play guitar.",
        wd2: "A guitar that inspires my music",
        wd3: "A guitar that delivers my sound",
        wd4: "A guitar that touches my heart",
        wd5: "A guitar that fulfills my passion",
        co: "Guangzhou Gaomin (GOMANS) Wood Products Co. LTD",
        addr: "No.16, Team 4, Deng Cun, Taiping Town, Conghua District, Guangzhou city, Guangdong Province CHINA",
        addr2: "UNIT 705B, 7/F, JOIN-IN HANG SING CENTRE, 2-16 KWAI FUNG CRESCENT, KWAI CHUNG, N.T. HONG KONG.",
        post: "510990",
        name: "Mr Tak Chan",
        tel: "Tel (China):  (+86) 13926190577",
        tel2: "Tel (France):  (+33) 614714844",
        founder: "Founder of Gomans Guitar",
    },
    err: {
        pend: "This guitar is still under development...",
        ret: "Back to Home Page",
        error: "Error：",
        ser: "Oops, something went wrong, please email us at this address: info@gomansguitar.com",
        sto: "Session timed out, please refresh the page and try again.",
        mus: "You have already Submitted the same request, We will contact you ASAP.",
        mul: "You have already ordered 2 guitars, please submit another after we contact you."
    }
}

export const frL = {
    message: "Bonjour",
    series: "Série",
    shape: "Série",
    detail: "Détails",
    xiistring: "12 STRINGS",
    rare: "Fait de bois rare",
    ply: "Player",
    // sng: "",
    gal: "Gallery",
    vr: "Gomans VR Exhibition Hall",
    currency: "¥",

    nav: {
        home: "Accueil",
        guitars: "Guitare",
        dlx: "Série DLX",
        ltd: "Gomans Spécial",
        xiistring: "Série 12 STRINGS",
        ff: "Fan-Fret",
        artist: "Joueurs",
        video: "Vidéo",
        about: "À propos de nous",
    },
    footer: {
        video: "Suivez nous",
        bili: "Bilibili",
        contact: "Contactez nous",
        copyright: "Copyright 2013 - 2023 © GomansGuitar.com tous droits réservés."
    },
    home: {
        // title1: "Pro Designed Bracing",
        // desc1: "Our chief Tuning ",
        // title2: "Pro Designed Bracing",
        // desc2: "Our chief Tuning ",
        // title3: "Pro Designed Bracing",
        // desc3: "Our chief Tuning ",
    },
    dlx: {
        fea: "Fonctions",
        brac: "Drilled Holes Bracing",
        nec: "Five Pieces Neck",
        ash: "Armrest Sound Holes",
        bdc: "Bevel Demi Cutaway",
        ssh: "Side Sound Hole",
    },
    ltd: {
        tit: "Gomans Spécial",
        ltd: "Collections limitée",
        spe: "Collections spéciales",
    },
    xii: {
        desc: "A 12 string guitar has a full and shiny sound, It can give you a sound so wide that a six string guitar cannot have."
    },
    shapes: {
        // bdl: "琴身长",
        // shd: "肩宽",
        // wai: "腰宽",
        // tum: "肚宽",
        // upt: "上厚",
        // dot: "下厚",
        // stl: "有效弦长",
        // tot: "总长"
    },
    frets: {
        f12: "12 Fret",
        f16: "16 Fret",
        fFan: "Fan Fret",
    },
    guitarProps: {
        series: "Série",
        name: "Le nom",
        model: "Modèle",
        shape: "Série",
        pics: "",
        Top: "Table",
        BackSides: "Eclisses & dos",
        head: "HeadStock",
        Neck: "Manche",
        Fingerboard: "Touche",
        fbinlay: "Touche inlay",
        brg: "Chevalet",
        rst: "Rosette",
        Nut: "Sillet",
        Saddle: "Sillet chevalet",
        TuningMachine: "Mécaniques",
        String: "Corde",
        FinishTop: "Finition Table",
        FinishNeck: "Finition Manche",
        FinishBody: "Finition Corps"
    },
    about: {
        wd0: "SOME WORDS FROM TAK",
        wd1: "Gomans Guitar was created because I have been loving to play guitar.",
        wd2: "A guitar that inspires my music",
        wd3: "A guitar that delivers my sound",
        wd4: "A guitar that touches my heart",
        wd5: "A guitar that fulfills my passion",
        co: "Guangzhou Gaomin (GOMANS) Wood Products Co. LTD",
        addr: "No.16, Team 4, Deng Cun, Taiping Town, Conghua District, Guangzhou city, Guangdong Province CHINA",
        post: "510990",
        name: "Mr Tak Chan",
        tel: "Tel(China): (+86)13926190577",
        tel2: "Tel(France): (+33)614714844",
        founder: "Le fondateur de Gomans Guitar"
    },
    err: {
        pend: "Cette guitare est encore en développement...",
        ret: "Accueil"
    }
}
